/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import PropTypes, { any, string } from "prop-types";
import * as React from 'react';
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";

function ProductList(PrList) {
  return (
    <Card sx={{ height: "100%" }}>
        
      <ArgonBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <ArgonTypography variant="h6" fontWeight="medium">
          Products Added
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox p={2}>
        <ArgonBox component="ul" display="flex" flexDirection="column" p={0} m={0}>

        {PrList.PrList.length>0 && PrList.PrList.map((result, index) =>
        <>
        <Invoice key={index} date={result.prodname} id={result.rackname} price={result.quantity} />
        </>
        )}        
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

ProductList.propTypes = {
    PrList: PropTypes.array
  };

export default ProductList;
