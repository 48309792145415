import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Alert, Checkbox, Snackbar, Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";
import ArgonInput from "components/ArgonInput";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserDialogBox({ open, handleClose, user, reloadDataHandler }) {
  const [isActive, setIsActive] = React.useState(false);
  const [Sopen, setOpen] = React.useState(false);

  const ShandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCheckboxChange = (event) => {
    setIsActive(!isActive);
  };
  React.useEffect(() => {
    if (user && user.IsActive === "1") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [user]);


  const propChange = async (event) => {
    event.preventDefault();
    const sendData = {
      Mobile: event.target.Mobile.value,
      Checkbox: isActive,
      id: user.Id,
    };
    const token = "PlyPark_APICalling_Token";
    const filter = "UpdateUser";
    await axios
      .post(`AllUpdate_Details.php?filter=${filter}`, {
        token: token,
        data: sendData,
      })
      .then((response) => {
        if (response.data.success === true) {
          setOpen(true);

          reloadDataHandler();
          handleClose();
        } else {
          alert("false");
        }
      })
      .catch((error) => {
        alert("false");
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Edit User"}</DialogTitle>
        <form onSubmit={propChange}>
          <DialogContent sx={{ width: 400, maxWidth: "100%" }}>
            <ArgonBox display="flex" alignItems="center" mb={2}>
              <ArgonInput placeholder="Name" value={user ? user.Name : ""} />
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" mb={2}>
              <input
                type="text"
                placeholder="Name"
                defaultValue={user ? user.Mobile_Number : ""}
                name="Mobile"
                maxLength={10}
                className="MuiInputBase-root MuiInputBase-colorPrimary css-fv3ez2-MuiInputBase-root"
                required={true}
              />
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center">
              <ArgonTypography
                variant="button"
                sx={{ cursor: "pointer", userSelect: "none" }}
                component="a"
                fontWeight="bold"
                textGradient
              >
                Is Active? &nbsp;
                <Checkbox onChange={handleCheckboxChange} checked={isActive} />
              </ArgonTypography>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton onClick={handleClose} variant="outlined" color="error">
              Cancel
            </ArgonButton>
            <ArgonButton variant="contained" color="success" type="submit">
              Submit
            </ArgonButton>
          </DialogActions>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={Sopen}
          autoHideDuration={5000}
          onClose={ShandleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={ShandleClose} severity="success" sx={{ width: "100%" }}>
            Success: User info has been updated
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

UserDialogBox.defaultProps = {
  open: false,
  user: null,
};

UserDialogBox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  reloadDataHandler: PropTypes.func,
  user: PropTypes.object,
};
