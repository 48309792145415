import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Alert, Snackbar, Stack, Switch,} from "@mui/material";
import PropTypes from "prop-types";
import ArgonInput from "components/ArgonInput";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditProductDialogBox({ open, handleClose, reloadDataHandler,user }) {
  const [Sopen, setOpen] = React.useState(false);
  const [includeTextField, setIncludeTextField] = React.useState(false);

  React.useEffect(() => {
    if (user && user.IsReorder === "1") {
        setIncludeTextField(true);
    } else {
        setIncludeTextField(false);
    }
  }, [user,setIncludeTextField]);

  const handleSwitchChange = (event) => {
    setIncludeTextField(event.target.checked);
  };

  const propChange = async (event) => {
    event.preventDefault();
    const sendData = {
      IsReorder: includeTextField ? 1 : 0,
      id: user.Id,
      qty: includeTextField ? event.target.Relevel.value : 0,
    };
    const token = "PlyPark_APICalling_Token";
    const filter = "UpdateProduct";
    await axios
      .post(`AllUpdate_Details.php?filter=${filter}`, {
        token: token,
        data: sendData,
      })
      .then((response) => {
        if (response.data.success === true) {
          setOpen(true);
          reloadDataHandler();
          handleClose();
        } 
      })
      .catch((error) => {
        alert("false");
      });
  };

  const ShandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

    const inputStyle = {
      width: '100%',
      padding: '10px',
      margin: '3px 0',
      boxSizing: 'border-box',
      border: '1px solid #ccc',
      borderRadius: '7px',
      outline: 'block',
      fontSize: '14px',
    };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Edit Product Details"}</DialogTitle>
        <form onSubmit={propChange}>
          <DialogContent sx={{ width: 400, maxWidth: "100%" }}>
            <ArgonBox display="flex" alignItems="center" mb={2}>
              <ArgonInput type="text" placeholder="Product Name" name="PName" required value={user ? user.Name : ''}/>
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" mb={2}>
              <ArgonTypography variant="h6">Set Reorder Level?</ArgonTypography>&nbsp;
              <Switch
                checked={includeTextField}
                onChange={handleSwitchChange}
                color="primary"
              />
            </ArgonBox>

            {includeTextField && (
              <ArgonBox display="flex" alignItems="center" mb={2}>
              <input
                type="text"
                placeholder="ReOrder Level"
                defaultValue={user ? user.Level : ""}
                name="Relevel"
                style={inputStyle}
              />
            </ArgonBox>
            )}


          </DialogContent>
          <DialogActions>
            <ArgonButton onClick={handleClose} variant="outlined" color="error">
              Cancel
            </ArgonButton>
            <ArgonButton variant="contained" color="info" type="submit">
              Update
            </ArgonButton>
          </DialogActions>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={Sopen}
          autoHideDuration={5000}
          onClose={ShandleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={ShandleClose} severity="success" sx={{ width: "100%" }}>
            Success: Product Updated
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

EditProductDialogBox.defaultProps = {
    open: false,
    user: null,
};
EditProductDialogBox.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    reloadDataHandler: PropTypes.func,
    user: PropTypes.object,
  };
