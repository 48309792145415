/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import * as React from 'react';
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI components
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import BaseLayout from "layouts/billing/components/BaseLayout";
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormDetails from "./FormDetails"
import { Icon } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import ProductList from "./ProductsList";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";



function AddChallan() {
  const navigate = useNavigate();
    const [Disable,setDisable]=React.useState(false);
    const [ProdCart,setProdCart]=React.useState([]);
    function updateCart(cart)
{
    setProdCart([...cart]);
}

const [basicDetails,setbasicDetails]=React.useState([]);

function getDetails(bd)
{
    setbasicDetails([...bd]);
    if(bd.length>0)
    {
        setDisable(true);
    }
}

const propChange = async (event) => {
  const token = "PlyPark_APICalling_Token";
  const filter = basicDetails[0]['type'] === 10?"AddSINChallan" :"AddSOUTChallan";
  
  try {
    const response = await axios.post(`AllAdd_Details.php?filter=${filter}`, {
      token: token,
      basicDetails: basicDetails,
      ProdCart: ProdCart,
      UserMob: localStorage.getItem('token')
    });

    if (Array.isArray(response.data)) {
      let allSuccess = response.data.every(res => res.success === true);

    if (allSuccess) {
      const newChallanIdObj = response.data.find(res => res.newChallanId !== undefined);
      const newChallanId = newChallanIdObj.newChallanId;

      const currentDate = new Date();
const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };

const formattedDate = currentDate.toLocaleString('en-IN', options)
  .split('/').reverse().join('-'); // Reformatting to yyyy-mm-dd

      const stype=basicDetails[0]['type'];
      const ptype=basicDetails[0]['payment'];

      navigate(`/PrintChallan?CHID=${newChallanId}&TDate=${formattedDate}&PTYPE=${ptype}&STYPE=${stype}`);
    } else {
      alert("At least one request failed");
    }
    }
    
  } catch (error) {
    return false;
    // Handle error, if needed
  }
};


const PrintChallan = () =>{
  const basicDetailsColumns = ["payment", "type"];
  if(ProdCart.length===0)
  {
    alert("Please add products to proceed to print!")
    return;
  }
    Swal.fire({
        title: "Are you sure?",
        text: "You want be able to edit challan after printing",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, print it!",
        
      }).then((result) => {
        if (result.isConfirmed) {
          propChange();
        }
      });
}

  return (
    <DashboardLayout>
        <DashboardNavbar />
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                {/* <ul>
        {ProdCart.length>0? ProdCart.map(ProdCart => (
          <li key={ProdCart.prodid}>{ProdCart.prodname}</li>
        )):''}
      </ul> */}

                  <FormDetails updateCart={updateCart} getDetails={getDetails}/>
                </Grid>
              </Grid>
              <br />
            </Grid>
            
            <Grid item xs={12} lg={4} style={{display: Disable?'block':'none'}}>
              <ProductList PrList={ProdCart}/>
            </Grid>
            
          </Grid>
            <div style={{display: Disable?'block':'none'}}>
          <Grid container>
          <Grid item xs={12} md={3}>
            </Grid>
            <Grid item xs={12} md={3}>
            <ArgonButton variant="outlined" color="error" onClick={PrintChallan}>
          <Icon sx={{ fontWeight: "bold" }}>print</Icon>
          &nbsp;Print Challan
        </ArgonButton>
            </Grid>
        </Grid>
        </div>
        </ArgonBox>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default AddChallan;
