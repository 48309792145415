// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import StockData from "./StockData"
import Button from '@mui/material/Button';
import { ButtonGroup, Icon, Typography } from "@mui/material";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import Grid from "@mui/material/Grid";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import ArgonButton from "components/ArgonButton";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import STDialogBox from './AddStock';
var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  minValidYear: 2000,
  maxValidYear: new Date().getFullYear(),
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};

function StockList() {
    const { columns, rows } = StockData;
    const gridRef = useRef();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const columnDefs = [
      {
        headerName: "Actions",
        field: "athlete",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        minWidth:250,
        suppressSizeToFit:true,
        cellRenderer: (params) => (
            <ArgonBox mr={1}>
              <ArgonButton variant="text" color="error">
                <Icon>delete</Icon>&nbsp;Delete
              </ArgonButton>
              <ArgonButton variant="text" color="dark">
              <Icon>edit</Icon>&nbsp;Edit
            </ArgonButton>
            </ArgonBox>
        ),
      },
      { headerName: "athlete", field: "athlete", tooltipField: "make", rowGroup: true},
      { headerName: "age", field: "age", rowGroup: true},
      { headerName: "country", field: "country" },
      { headerName: "year", field: "year" },
      {
        headerName: "date",
        field: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { headerName: "sport", field: "sport" },
      { headerName: "gold", field: "gold" },
      { headerName: "silver", field: "silver" },
      { headerName: "bronze", field: "bronze" },
      { headerName: "total", field: "total", filter: false },
    ];

    const defaultColDef = useMemo(() => ({
      sortable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      resizable: true,
      minWidth:150,
      
    }));
    
    const onGridReady = (params) => {
      fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
        .then((result) => result.json())
        .then((result) => {
          params.api.applyTransaction({ add: result });
        });
    };

    const onSelectionChanged = useCallback(() => {
      const selectedRows = gridRef.current.api.getSelectedRows();
    }, []);
  
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const saveState = useCallback(() => {
      window.colState = gridRef.current.columnApi.getColumnState();
      closeSideBarToolPanel();
    }, []);
  
    const restoreState = useCallback(() => {
      if (!window.colState) {
        return;
      }
      gridRef.current.columnApi.applyColumnState({
        state: window.colState,
        applyOrder: true,
      });
      closeSideBarToolPanel();
    }, []);
  
    const resetState = useCallback(() => {
      gridRef.current.columnApi.resetColumnState();
      closeSideBarToolPanel();
    }, []);
  
    const sideBar = useMemo(() => {
      return {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "save",
            labelDefault: "Save",
            iconKey: "menu",
            toolPanel: () => (
              <ArgonBox mb={5}>
                <ArgonButton variant="contained" color="dark" onClick={handleClickOpen}>Save State</ArgonButton><br /><br />
                <ArgonButton variant="contained" color="dark" onClick={handleClickOpen}>Restore State</ArgonButton><br /><br />
                <ArgonButton variant="contained" color="dark" onClick={handleClickOpen}>Reset State</ArgonButton>  
                
              </ArgonBox>
            ),
          },
        ],
      };
    }, []);
    
    
    const closeSideBarToolPanel = () => [gridRef.current.api.closeToolPanel()];

    return (
      <DashboardLayout>
        <STDialogBox open={open} handleClose={handleClose} />
        <DashboardNavbar />
        <ArgonBox py={3}>
          <ArgonBox mb={3}>
            <Card>
              <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                
              <ArgonTypography variant="h6">Stocks List</ArgonTypography>
                {/* <ArgonButton variant="gradient" color="info" onClick={onBtPrint}>
          <Icon>print</Icon>
          &nbsp;Print
        </ArgonButton> */}
                
                <ArgonButton variant="gradient" color="dark" onClick={handleClickOpen}>
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;Add New Product
        </ArgonButton>
        
        <ArgonTypography variant="h6">page size&nbsp;&nbsp;
        <select onChange={onPageSizeChanged} id="page-size">
          <option value="10">10</option>
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </select>
        </ArgonTypography>
      
        </ArgonBox>

              <ArgonBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <div className="ag-theme-alpine" style={{ width: "100%", height: "550px" }} id="myGrid">
                <AgGridReact
        ref={gridRef} // Ref for accessing Grid's API
        // rowData={rowData} // Row Data for Rows

        columnDefs={columnDefs} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows
        rowMultiSelectWithClick={true}
        onSelectionChanged={onSelectionChanged}
        // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
        enableBrowserTooltips={true}
        onGridReady={onGridReady}
        pagination={true}
        // paginationAutoPageSize={true}
        paginationPageSize={10}
        sideBar={sideBar}
      />
      </div>
              </ArgonBox>
            </Card>
          </ArgonBox>
          
        </ArgonBox>
        <Footer />
      </DashboardLayout>
    );
  }
  
export default StockList;