//Main Page for Dashboard Page Code

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import SalesTable from "examples/Tables/SalesTable";
import CategoriesList from "examples/Lists/CategoriesList";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import Slider from "layouts/dashboard/components/Slider";

// Data
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import salesTableData from "layouts/dashboard/data/salesTableData";
import categoriesListData from "layouts/dashboard/data/categoriesListData";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Default() {
  const { size } = typography;
  // function createData(name, calories, fat, carbs, protein) {
  //   return { name, calories, fat, carbs, protein };
  // }
  
  // const rows = [
  //   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  //   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  //   createData('Eclair', 262, 16.0, 24, 6.0),
  //   createData('Cupcake', 305, 3.7, 67, 4.3),
  //   createData('Gingerbread', 356, 16.0, 49, 3.9),
  // ];

  const [challan, setchallan] = useState('');
  const [racks, setracks] = useState('');
  const [godown, setgodown] = useState('');
  const [users, setusers] = useState('');
  const [sin, setsin] = useState([]);
  const [sout, setsout] = useState([]);

  useEffect(() => {
    const token = 'PlyPark_APICalling_Token';
    const filter = 'DashboardDetails';
    axios.post(`AllGet_Details.php?filter=${filter}`, 
    { 
      token: token,
    })
   .then(response => {
      setchallan(response.data['totalChallans']);
      setgodown(response.data['totalGodowns']);
      setracks(response.data['totalRacks']);
      setusers(response.data['totalUsers']);
      setsin(response.data['SinResults']);
      setsout(response.data['SoutResults']);
   })
   .catch(error => {
   });
  }, []);
  
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Today's Challan"
              count={challan}
              icon={{ color: "info", component: <i className="ni ni-money-coins" /> }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Total Racks"
              count={racks}
              icon={{ color: "error", component: <i className="ni ni-world" /> }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="total godowns"
              count={godown}
              icon={{ color: "success", component: <i className="ni ni-paper-diploma" /> }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="total users"
              count={users}
              icon={{ color: "warning", component: <i className="ni ni-single-02" /> }}
            />
          </Grid>
        </Grid>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <Card>
            
      <ArgonBox pt={2} px={2}>
        <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize" color="warning">
          Recent Stock In&apos;s
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox p={2}>
        <ArgonBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        
        <TableContainer component={Paper}>
      <Table aria-label="simple table">
      
          <TableRow>
          
            <TableCell variant="head"><ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">Date&Time</ArgonTypography></TableCell>
            <TableCell variant="head"><ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">Godown</ArgonTypography></TableCell>
            <TableCell variant="head"><ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">Quantity</ArgonTypography></TableCell>
          </TableRow>
        
        <TableBody>
          {sin.map((row,index) => (
            <TableRow key={index}>
              <TableCell align="left"> <ArgonTypography variant='text'>{row.LastDate}</ArgonTypography></TableCell>
              <TableCell align="left"> <ArgonTypography variant='text'>{row.GodownName}</ArgonTypography></TableCell>
              <TableCell align="left"> <ArgonTypography variant='subtitle2' color="success">{row.TotalQuantity}</ArgonTypography></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
        </ArgonBox>
      </ArgonBox>
          </Card>
          </Grid>
          <Grid item xs={12} md={6}>
          <Card>
            
      <ArgonBox pt={2} px={2}>
        <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize" color="primary">
          Recent Stock Out
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox p={2}>
        <ArgonBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        <TableContainer component={Paper}>
      <Table aria-label="simple table">
      
      <TableRow>
          
          <TableCell variant="head"><ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">Date&Time</ArgonTypography></TableCell>
          <TableCell variant="head"><ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">Godown</ArgonTypography></TableCell>
          <TableCell variant="head"><ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">Quantity</ArgonTypography></TableCell>
        </TableRow>
        
        <TableBody>
          {sout.map((row,index) => (
            <TableRow key={index}>
            <TableCell align="left"> <ArgonTypography variant='text'>{row.LastDate}</ArgonTypography></TableCell>
            <TableCell align="left"> <ArgonTypography variant='text'>{row.GodownName}</ArgonTypography></TableCell>
            <TableCell align="left"> <ArgonTypography variant='subtitle2' color="error">{row.TotalQuantity}</ArgonTypography></TableCell>
          </TableRow>
          ))}
        </TableBody>
        
      </Table>
    </TableContainer>
        </ArgonBox>
      </ArgonBox>
          </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
