import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Alert,Snackbar, Stack,} from "@mui/material";
import PropTypes from "prop-types";
import ArgonInput from "components/ArgonInput";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RackDialogBox({ open, handleClose, reloadDataHandler }) {
  const [Sopen, setOpen] = React.useState(false);
  const [godown, setGodown] = React.useState([]);
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const propChange = async (event) => {
    event.preventDefault();
    const sendData = {
      Gid: event.target.selid.value,
      Name: event.target.RName.value
    };
    
    const token = "PlyPark_APICalling_Token";
    const filter = "AddRack";
    await axios
      .post(`AllAdd_Details.php?filter=${filter}`, {
        token: token,
        data: sendData,
      })
      .then((response) => {
        if (response.data.success === true) {
          setOpen(true);
          event.target.RName.value = "";
          setAge('');

          reloadDataHandler();
          handleClose();
        } 
      })
      .catch((error) => {
        alert("false");
      });
  };

  const ShandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "PlyPark_APICalling_Token";
        const filter = "GetGodownDetails";
        const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
          token: token,
        });
        const godownDetails = response.data.data;
        setGodown(godownDetails);
      } catch (error) {
        alert("Internal Server Error!")
        // Handle error as needed
      }
    };

    fetchData();
  }, [godown]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Create a New Rack"}</DialogTitle>
        <form onSubmit={propChange}>
          <DialogContent sx={{ width: 400, maxWidth: "100%" }}>
            <Box sx={{ minWidth: 120,mb:2}}>
              <FormControl fullWidth>
                <Select
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  name="selid"
                >
                  <MenuItem value="" disabled>
                    ****Select the Godown**** 
                  </MenuItem>
                  {godown && godown.map((godownItem) => (
                    <MenuItem key={godownItem.Id} value={godownItem.Id}>
                      {godownItem.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <ArgonBox display="flex" alignItems="center" mb={0}>
              <ArgonInput type="text" placeholder="Rack Name" name="RName" required/>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton onClick={handleClose} variant="outlined" color="error">
              Cancel
            </ArgonButton>
            <ArgonButton variant="contained" color="success" type="submit">
              Create
            </ArgonButton>
          </DialogActions>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={Sopen}
          autoHideDuration={5000}
          onClose={ShandleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={ShandleClose} severity="success" sx={{ width: "100%" }}>
            Success: Rack Added
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

RackDialogBox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  reloadDataHandler: PropTypes.func,
};
