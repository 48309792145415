/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import axios from "axios";
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";

function Mainpage() {
  const[ip,setIP]=React.useState('');

  React.useEffect(() => {
    const getIpAddress = async () => {
      try {
        const response = await axios.get('https://api64.ipify.org?format=json');
        const ipAddress = response.data.ip;
        setIP(ipAddress);
      } catch (error) {

      }
    };
    getIpAddress();
  }, []);
  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6)
          )}, url(${bgImage})`,
        backgroundPositionY: "50%",
      }}
    >
      <DashboardNavbar />
      <br />
      <ArgonBox mb={3}>
        <Card>
          <ArgonBox pt={2} px={2}>
            <ArgonBox mb={0.5}>
              <ArgonTypography variant="h6" fontWeight="medium">
                Reports
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mb={1}>
              <ArgonTypography variant="button" fontWeight="regular" color="text">
                View & Print Reports
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={4}>
                <DefaultProjectCard
                  label="Report #1"
                  title="E-Challan"
                  description="View and print daily,monthly,yearly e-challan reports"
                  action={{
                    type: "internal1",
                    color: "info",
                    label: "View Challans",
                    route: `/ChallanReport?IPAddress=${ip}`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <DefaultProjectCard
                  label="Report #2"
                  title="Stocks"
                  description="View and print complete stock details with quantity and rack details"
                  action={{
                    type: "internal2",
                    route: `/StocksReport?IPAddress=${ip}`,
                    color: "info",
                    label: "View Stocks",
                    
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <DefaultProjectCard
                  label="Report #3"
                  title="Rack Level Report"
                  description="View and print the Rack wise stocks report with godown details"
                  action={{
                    type: "internal3",
                    route: `/RackWiseStocksReport?IPAddress=${ip}`,
                    color: "info",
                    label: "View Rack Level Stocks",
                  }}
                />
              </Grid>
            </Grid>
          </ArgonBox>
        </Card>
      </ArgonBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Mainpage;
