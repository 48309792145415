import React from 'react';
import { Typography, Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material';
import IllustrationLayout from 'layouts/authentication/components/IllustrationLayout';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ArgonTypography from 'components/ArgonTypography';

const StockChallan = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const CHID = queryParams.get('CHID');
    const TDate = queryParams.get('TDate');
    const PTYPE = queryParams.get('PTYPE');
    const STYPE = queryParams.get('STYPE');

    const [data, setData] = React.useState([]);
    let totalQty = 0;
    const printPage = () => {
        window.print();
    };

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const token = "PlyPark_APICalling_Token";
                const filter = "GetChallanDetails";
                const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
                  token: token,
                  Tdate: TDate,
                  Cid: CHID,
                  Ptype: PTYPE,
                  Stype: STYPE
                });
        
                const Challandata = response.data.data;
                setData(Challandata);
              } catch (error) {
                alert(error.response.data.message);
              }
        };
        fetchData();
      }, []);

    return (
        <PageLayout background="white">
            <Paper style={{ width: '100mm', margin: '5px auto', padding: '5px' }}>
            <div style={{ textAlign: 'center'}}>
                <Typography variant="subtitle2">PlyPark</Typography>
            </div>
            {data && data.length > 0 && (
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1px'}}>
                <div style={{ textAlign: 'left' }}>
                    <Typography variant="caption" display="block" gutterBottom>CHALLAN ID: {data[0]['Challan_Id']} </Typography>
                    <Typography variant="caption" display="block" gutterBottom>CHALLAN TYPE: {data[0]['SType']===10?"STOCK IN":"STOCK OUT"} </Typography>
                    <Typography variant="caption" display="block" gutterBottom>PAYMENT TYPE: {data[0]['Ptype']===10?"CASH":"CREDIT"}</Typography>
                </div>
                <div style={{ textAlign: 'right' }}>
                <Typography variant="caption" display="block" gutterBottom>GODOWN: {data[0]['GodownName'].toUpperCase()}</Typography>
                <Typography variant="caption" display="block" gutterBottom>DATE: {new Date(data[0].Date_Created).toLocaleDateString("en-GB")}</Typography>
                <Typography variant="caption" display="block" gutterBottom>TTIME: {new Date(data[0].Date_Created).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</Typography>
                </div>
            </div>
            )}
            {/* <Typography variant="subtitle2" align="center" gutterBottom>Stock In</Typography> */}
            <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
                <Table size="small" aria-label="a dense table" sx={{ minWidth: 200 }}>
                <TableBody>
                        <TableRow>
                            <TableCell align="left"><Typography variant="subtitle2">PRODUCT</Typography></TableCell>
                            <TableCell align="left"><Typography variant="subtitle2">QTY</Typography></TableCell>
                            <TableCell align="left"><Typography variant="subtitle2">RAK</Typography></TableCell>
                        </TableRow>
                    
                        {data && data.length > 0 && data.map((CRow,index)=>{
                            const qty = CRow.Qty || 0;
                            totalQty += qty;
                            return(
                                <TableRow key={index}>
                                <TableCell align="left"><ArgonTypography variant="subtitle2">{index + 1}&nbsp;&nbsp;{CRow.ProductName.toUpperCase()}</ArgonTypography></TableCell>
                                <TableCell align="left"><ArgonTypography variant="subtitle2">{CRow.Qty}</ArgonTypography></TableCell>
                                <TableCell align="left"><ArgonTypography variant="subtitle2">{CRow.RackName.toUpperCase()}</ArgonTypography></TableCell>
                            </TableRow> 
                             )
                        }
                        )}
                        <TableRow>
                           <TableCell align="left"><ArgonTypography variant="subtitle2"></ArgonTypography>Total QTY: </TableCell>
                           <TableCell align="left"><ArgonTypography variant="h6"></ArgonTypography>{totalQty}</TableCell>
                           <TableCell align="left"></TableCell>
                       </TableRow> 
                    </TableBody>
                </Table>
            </TableContainer>

            <div style={{ textAlign: 'center', marginTop: '20px' }} className="print-button">
        <Button variant="contained" onClick={printPage}>
          Print
        </Button>
      </div>
      <style>{`
        @media print {
          .print-button {
            display: none;
          }
        }
      `}</style>
        </Paper>
        </PageLayout>
        
    );
};

export default StockChallan;
