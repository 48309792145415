// @mui material components
import Card from "@mui/material/Card";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Added import for PropTypes

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonBadge from "components/ArgonBadge";
import ArgonAvatar from "components/ArgonAvatar";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data

import { Icon, Typography } from "@mui/material";

import UserDialogBox from "./AddUser";
import axios from "axios";
import UserDialogBox1 from "./AddUserOrig";

// Move Author and FunctionT components outside UserList component
function Author({ name, email }) {
  return (
    <ArgonBox display="flex" alignItems="left">
      <ArgonBox mr={0}>
        <ArgonAvatar alt={name} size="sm" variant="rounded" />
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="button" fontWeight="medium">
          {name}
        </ArgonTypography>
        <ArgonTypography variant="caption" color="secondary">
          {email}
        </ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
}

function UserList() {
  const [DBRows, setRows] = useState();
  // const [Roles, setRoles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [reloadData, setReloadData] = useState(false);

  const handleClickOpen = (user) => {
    setSelectedUser(user); // Set the selected user when the Edit button is clicked
    setOpen(true);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose = () => {
    setSelectedUser(null); // Clear the selected user when the dialog is closed
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const reloadDataHandler = () => {
    setReloadData(!reloadData);
  };

  const columns = [
    {
      name: "action",
      align: "left",
      customElement: (value, index) => (
        <ArgonBox>
          <ArgonButton
            variant="text"
            color="dark"
            onClick={() => handleClickOpen(DBRows[index].originalUser)}
          >
            <Icon>edit</Icon>&nbsp;Edit
          </ArgonButton>
        </ArgonBox>
      ),
    },
    { name: "Details", align: "center" },
    { name: "Access", align: "left" },
    { name: "status", align: "center" },
    { name: "Created_On", align: "center" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "PlyPark_APICalling_Token";
        const filter = "Complete";
        const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
          token: token,
        });

        const users = response.data.data;
        // Fetch roles for each user
        const fetchRoles = async (user) => {
          const filterRoles = "GetUserRoles";
          const data = user.Mobile_Number;
          
          const responseRoles = await axios.post(`AllGet_Details.php?filter=${filterRoles}`, {
            token: token,
            data: data,
          });
          const roles = responseRoles.data.data.map((row) => Object.values(row)).flat();
          return roles;
        };

        const rolesPromises = users.map(fetchRoles);

        const usersWithRoles = await Promise.all(rolesPromises);
        // Set rows for the table
        setRows(
          usersWithRoles.map((roles, index) => (
            {
            Details: <Author name={users[index].Name} email={users[index].Mobile_Number} />,
            Access: (
              <ArgonBox display="flex" flexDirection="column">
                <ArgonTypography
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ whiteSpace: "normal" }}
                >
                  Dashboard, {roles.includes(1) ? "Manage Users, " : ""}
                  {roles.includes(2) ? "Manage Godowns, " : ""}
                  {roles.includes(3) ? "Manage Challans, " : ""}
                  {roles.includes(4) ? "Reports " : ""}
                </ArgonTypography>
              </ArgonBox>
            ),
            status:
              parseInt(users[index].IsActive) === 1 ? (
                <ArgonBadge
                  variant="gradient"
                  badgeContent="Active"
                  color="success"
                  size="xs"
                  container
                />
              ) : (
                <ArgonBadge
                  variant="gradient"
                  badgeContent="Inactive"
                  color="error"
                  size="xs"
                  container
                />
              ),
            Created_On: (
              <ArgonTypography variant="caption" color="secondary" fontWeight="medium">
                {new Date(users[index].Date_Created).toLocaleDateString("en-GB")}
              </ArgonTypography>
            ),
            // Use a function to handle the click and pass the original user
            action: (
              <ArgonBox>
                <ArgonButton
                  variant="text"
                  color="dark"
                  onClick={() => handleClickOpen(users[index])}
                >
                  <Icon>edit</Icon>&nbsp;Edit
                </ArgonButton>
              </ArgonBox>
            ),
          }))
        );
      } catch (error) {

        alert("Internal Error! Kindly contact admin");
      }
    };
    fetchData();
  }, [reloadData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <ArgonTypography variant="h6">Users List</ArgonTypography>
              <ArgonButton variant="gradient" color="dark" onClick={handleClickOpen1}>
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;Add New User
              </ArgonButton>
            </ArgonBox>
            <ArgonBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={DBRows} />
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
      <UserDialogBox
        open={open}
        handleClose={handleClose}
        user={selectedUser}
        reloadDataHandler={reloadDataHandler}
      />
      <UserDialogBox1
        open={open1}
        handleClose={handleClose1}
        reloadDataHandler={reloadDataHandler}
      />
    </DashboardLayout>
  );
}

Author.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
};
export default UserList;
