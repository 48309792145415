/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Illustration() {
  const [open, setOpen] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [sever, setSever] = React.useState('error');
const handleClick = (Message) => {
  setMessage(Message)
  setOpen(true);
};
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      Mobile: event.target.Mobile.value,
      Password: event.target.Password.value,
    };
    const token = 'PlyPark_APICalling_Token';
    const filter = 'VLogin';
  
    try {
      const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
        token: token,
        data: data,
      });
      const responseData = response.data;
      if (responseData.success) {
        const userMobile = responseData.data[0]?.Mobile_Number;
  
        if (userMobile) {
          localStorage.setItem('token', userMobile);
          setSever('success');
          const mess="Login Successfully";
          handleClick(mess);
          navigate('/dashboard');
          
          // navigate('/dashboard');
          // return; // Exit early to prevent further execution
        }
      }
      else{
        const errorMessage = responseData.message || "Internal server issue";
        handleClick(errorMessage);
      }
      // If the response doesn't match the expected structure or userMobile is undefined
      
    } catch (error) {
      // Handle network errors or other exceptions
      const errorMessage = 'Internal Server Error';
      handleClick(errorMessage);
    }
  };
  

  // Check if the user is already logged in
  React.useEffect(() => {
    const isAuthenticated = localStorage.getItem('token');

    if (isAuthenticated) {
      navigate('/dashboard'); // Redirect to the dashboard if authenticated
    }
  }, [navigate]);

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your Mobile Number and Password to Sign in"
      illustration={{
        title: '"Welcome to PlyPark"',
        description:
          "Decorative Laminates Plywood & Veneers",
      }}
    >
      <ArgonBox>

      <form onSubmit={handleSubmit}>
        <ArgonBox mb={2}>
          <ArgonInput type="number" placeholder="Mobile Number" size="large" name="Mobile" required={true}/>
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput type="password" placeholder="Password" size="large" name="Password" required={true}/>
        </ArgonBox>  
        <ArgonBox mt={4} mb={1}>
          <ArgonButton type='submit' color="info" size="large" fullWidth >
            Sign In
          </ArgonButton>
        </ArgonBox>
        {/* <ArgonBox mt={3} textAlign="center"> */}
          {/* <ArgonTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <ArgonTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
            >
              Sign up
            </ArgonTypography>
          </ArgonTypography> */}
        {/* </ArgonBox> */}
        </form>
      </ArgonBox>
      
      <Stack spacing={2} sx={{ width: '100%' }}>
  <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
    <Alert onClose={handleClose} severity={sever} sx={{ width: '100%' }}>
       {Message}
    </Alert>
  </Snackbar>
</Stack>

    </IllustrationLayout>
  );
}

export default Illustration;
