import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { Alert, Snackbar, Stack, TextField } from '@mui/material';
import PropTypes from "prop-types";
import ArgonInput from 'components/ArgonInput';
import ArgonBox from "components/ArgonBox";
import ArgonButton from 'components/ArgonButton';
import axios from 'axios';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GDDialogBox({GDopen,handleCloseGD, reloadDataHandler}) {
  const [Sopen, setOpen] = React.useState(false);

    const propChange = async (event) => {
        event.preventDefault();
        const sendData = {
            Name:event.target.GName.value
        }

    const token = "PlyPark_APICalling_Token";
    const filter = "AddGodown";
    await axios
      .post(`AllAdd_Details.php?filter=${filter}`, {
        token: token,
        data: sendData,
      })
      .then((response) => {
        if (response.data.success === true) {
          setOpen(true);
          reloadDataHandler();
          handleCloseGD();
          event.target.GName.value = "";
        } else {
          alert("false");
        }
      })
      .catch((error) => {
        alert("false");
      });

      }

      const ShandleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    return (
    <React.Fragment>
      <Dialog
        open={GDopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseGD}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Create a New Godown"}</DialogTitle>
        <form onSubmit={propChange}>
        <DialogContent sx={{width: 400,maxWidth: '100%'}}>
        <ArgonBox display="flex" alignItems="center" mb={0}>
        <ArgonInput type="text" placeholder="Name" name="GName" required/>
        </ArgonBox>  
        </DialogContent>
        <DialogActions>
          <ArgonButton onClick={handleCloseGD} variant="outlined" color="error">Cancel</ArgonButton>
          <ArgonButton variant="contained" color='success' type='submit'>Add</ArgonButton>
        </DialogActions>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={Sopen}
          autoHideDuration={5000}
          onClose={ShandleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={ShandleClose} severity="success" sx={{ width: "100%" }}>
            Success: Godown Added
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

GDDialogBox.propTypes = {
    GDopen: PropTypes.bool,
    handleCloseGD: PropTypes.func,
    reloadDataHandler: PropTypes.func,
  };