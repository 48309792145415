import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Alert, Checkbox, Snackbar, Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";
import ArgonInput from "components/ArgonInput";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserDialogBox1({ open, handleClose, reloadDataHandler }) {
  const [user, setuser] = React.useState(false);
  const [godown, setgodown] = React.useState(false);
  const [challan, setchallan] = React.useState(false);
  const [report, setreport] = React.useState(false);
  const [Sopen, setOpen] = React.useState(false);
  const [Message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const ShandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCheckboxChange = () => {
    setuser(!user);
  };

  const handleCheckboxChange1 = () => {
    setgodown(!godown);
  };

  const handleCheckboxChange2 = () => {
    setchallan(!challan);
  };

  const handleCheckboxChange3 = () => {
    setreport(!report);
  };

  const propChange = async (event) => {
    event.preventDefault();
    const sendData = {
      Name: event.target.UName.value,
      Mobile: event.target.UMob.value,
      Password: event.target.UPass.value,
      Checkbox: {
        ManageUsers: user,
        ManageGodown: godown,
        ManageChallans: challan,
        ManageReports: report,
      },
    };
    const token = "PlyPark_APICalling_Token";
    const filter = "AddUser";
    await axios
      .post(`AllAdd_Details.php?filter=${filter}`, {
        token: token,
        data: sendData,
      })
      .then((response) => {
        if (response.data.success === true) {
          setOpen(true);
          setMessage("Success: User has been added");
          setSeverity("success");
          reloadDataHandler();
          event.target.UName.value = "";
          event.target.UMob.value = "";
          event.target.UPass.value = "";
          setuser(false);
          setgodown(false);
          setchallan(false);
          setreport(false);

          handleClose();
        }
      })
      .catch((error) => {
        setOpen(true);
        setMessage("Error: Unable to add user");
        setSeverity("error");
        reloadDataHandler();
        handleClose();
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add New User"}</DialogTitle>
        <form onSubmit={propChange}>
          <DialogContent sx={{ width: 400, maxWidth: "100%" }}>
            <ArgonBox display="flex" alignItems="center" mb={2}>
              <ArgonInput placeholder="Name" type="text" name="UName" required/>
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" mb={2}>
              <ArgonInput placeholder="Mobile Number" type="number" name="UMob" required/>
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" mb={2}>
              <ArgonInput placeholder="Password" type="password" name="UPass" required/>
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" mb={1}>
              <ArgonTypography
                variant="button"
                sx={{ cursor: "pointer", userSelect: "none" }}
                component="a"
                fontWeight="bold"
                textGradient
              >
                Select Access - <br />
                Manage Users &nbsp;
                <Checkbox onChange={handleCheckboxChange} checked={user} />
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center" mb={1}>
              <ArgonTypography
                variant="button"
                sx={{ cursor: "pointer", userSelect: "none" }}
                component="a"
                fontWeight="bold"
                textGradient
              >
                Manage Godowns/Racks/Stocks &nbsp;
                <Checkbox onChange={handleCheckboxChange1} checked={godown} />
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center" mb={1}>
              <ArgonTypography
                variant="button"
                sx={{ cursor: "pointer", userSelect: "none" }}
                component="a"
                fontWeight="bold"
                textGradient
              >
                Manage Challans&nbsp;
                <Checkbox onChange={handleCheckboxChange2} checked={challan} />
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center" mb={1}>
              <ArgonTypography
                variant="button"
                sx={{ cursor: "pointer", userSelect: "none" }}
                component="a"
                fontWeight="bold"
                textGradient
              >
                Generate Report &nbsp;
                <Checkbox onChange={handleCheckboxChange3} checked={report} />
              </ArgonTypography>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton onClick={handleClose} variant="outlined" color="error">
              Cancel
            </ArgonButton>
            <ArgonButton variant="contained" color="success" type="submit">
              Submit
            </ArgonButton>
          </DialogActions>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={Sopen}
          autoHideDuration={5000}
          onClose={ShandleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={ShandleClose} severity={severity} sx={{ width: "100%" }}>
            {Message}
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

UserDialogBox1.defaultProps = {
  open: false,
};

UserDialogBox1.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  reloadDataHandler: PropTypes.func,
};
