import { useLocation, useNavigate } from 'react-router-dom';
import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import Footer from "examples/Footer";

import styles from './Style.css';
import { Alert, Icon, Snackbar, Stack, Typography } from "@mui/material";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import ArgonButton from "components/ArgonButton";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import PageLayout from "examples/LayoutContainers/PageLayout";
import axios from 'axios';
import ArgonBadge from 'components/ArgonBadge';
import { red } from '@mui/material/colors';

var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("-");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: new Date().getFullYear(),
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
  };
  
  const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '100%';
    eGridDiv.style.height = '700px';
    api.setDomLayout('normal');
  };
export default function StockReport() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const IP = queryParams.get('IPAddress');
    if(!IP)
    {
        navigate(`/ViewReports`);
    }
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const [open, setOpen] = React.useState(false);
  const [Sopen, setSOpen] = React.useState(false);

  const checkboxSelection = (params) => {
    return params.node.group === true;
  };
  
  const checkbox = (params) => {
    return params.node.group === true;
  };

  const onBtPrint = useCallback(() => {
    setPrinterFriendly(gridRef.current.api);
    setTimeout(() => {
      print();
      setNormal(gridRef.current.api);
    }, 1000);
  }, [print]);

   const columnDefs = [
      
      { headerName: "Id", field: "Id", tooltipField: "Product Id"},
      { headerName: "Product", field: "Name",filter:'agTextColumnFilter', suppressMenu: true},
      { headerName: "Qty", field: "TotalQuantity"},
      {
        headerName: "Is Reorder",
        field: "IsReorder",
        cellRenderer: params => {
          return params.value === 1 ? 
          <ArgonBadge
                  variant="gradient"
                  badgeContent="Yes"
                  color="success"
                  size="xs"
                  container
                /> 
          : <ArgonBadge
          variant="gradient"
          badgeContent="No"
          color="error"
          size="xs"
          container
        />;
        },
      },
      {
        headerName: "Re Level",
        field: "Level",
        cellRenderer: params => {
          const isReorder = params.data.IsReorder === 1;
          return isReorder ? <Typography variant="button" color='#1b5e20'>{params.value}</Typography> : <Typography variant="button" color='error'>{'NA'}</Typography>;
        },
      },
      {
        headerName: "Date Created",
        field: "Date_Created",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
    ];

    // const groupDefaultExpanded = 1;

    const onGridReady = async (params) => {

        try {
            const token = "PlyPark_APICalling_Token";
            const filter = "GetStockDetailsReport";
            const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
              token: token
            });
    
            const StockData = response.data.data;
            params.api.applyTransaction({ add: StockData });
          } catch (error) {

            alert(error.response.data.message);
          }
    };
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const defaultColDef = useMemo(() => ({
      sortable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      resizable: true,
      minWidth:10,
      
    }));
  
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const saveState = useCallback(() => {
      window.colState = gridRef.current.columnApi.getColumnState();
      closeSideBarToolPanel();
    }, []);
  
    const restoreState = useCallback(() => {
      if (!window.colState) {
        return;
      }
      gridRef.current.columnApi.applyColumnState({
        state: window.colState,
        applyOrder: true,
      });
      closeSideBarToolPanel();
    }, []);
  
    const resetState = useCallback(() => {
      gridRef.current.columnApi.resetColumnState();
      closeSideBarToolPanel();
    }, []);
  
    const sideBar = useMemo(() => {
      return {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "save",
            labelDefault: "Save",
            iconKey: "menu",
            toolPanel: () => (
              <ArgonBox mb={5}>
                <ArgonButton variant="contained" color="dark" onClick={handleClickOpen}>Save State</ArgonButton><br /><br />
                <ArgonButton variant="contained" color="dark" onClick={handleClickOpen}>Restore State</ArgonButton><br /><br />
                <ArgonButton variant="contained" color="dark" onClick={handleClickOpen}>Reset State</ArgonButton>  
                
              </ArgonBox>
            ),
          },
        ],
      };
    }, []);
    
    const closeSideBarToolPanel = () => [gridRef.current.api.closeToolPanel()];

    const ShandleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setSOpen(false);
      };

  return (
    <div style={containerStyle}>
 <PageLayout background="white">
<ArgonBox>
          <ArgonBox mb={2}>
            <Card>
                
            <ArgonBox p={1} display="flex" flexDirection="column" alignItems="center">
  <ArgonTypography variant="h6">Plypark Stocks Report</ArgonTypography>
            </ArgonBox>
            <div className="print-button">
            <ArgonBox p={1} display="flex" alignItems="center" justifyContent="space-between">
  <ArgonTypography variant="h6">
    Page size&nbsp;&nbsp;
    <select style={{
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid green',
        backgroundColor: '#f8f8f8',
        color: '#800000',
        fontSize: '14px',
      }} onChange={onPageSizeChanged} id="page-size">
      <option value="20">20</option>
      <option value="100">100</option>
      <option value="500">500</option>
      <option value="1000">1000</option>
    </select>
  </ArgonTypography>

  <ArgonBox display="flex">
    <ArgonButton
      variant="outlined"
      color="info"
      onClick={onBtPrint}
      style={{ marginRight: '20px' }}
    >
      <Icon>print</Icon>&nbsp; Print
    </ArgonButton>
  </ArgonBox>
            </ArgonBox>
            </div>
                <div className="ag-theme-alpine" style={{ width: "100%", height:"700px" }} id="myGrid">
                <AgGridReact
        ref={gridRef}
        
        columnDefs={columnDefs} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        suppressRowClickSelection={true}
        enableBrowserTooltips={true}
        onGridReady={onGridReady}
        pagination={true}
        // paginationAutoPageSize={true}
        paginationPageSize={20}
        sideBar={sideBar}
        
      />
      </div>
            </Card>
          </ArgonBox>
          
        </ArgonBox>
        <Footer />
    </PageLayout>
    </div>
   
  );
}
