import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { Alert, Box, FormControl, MenuItem, Select, Snackbar, Stack, TextField } from '@mui/material';
import PropTypes from "prop-types";
import ArgonInput from 'components/ArgonInput';
import ArgonBox from "components/ArgonBox";
import ArgonButton from 'components/ArgonButton';
import axios from 'axios';
import ArgonTypography from 'components/ArgonTypography';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RTDialogBox({open,handleClose, reloadDataHandler}) {
  const [Sopen, setOpen] = React.useState(false);
const [svalue,setsvalue]=React.useState('');
const [tvalue,settvalue]=React.useState('');
const [rack,setrack]=React.useState([]);
const [godowns, setGodowns] = React.useState([]);
const [selectedGodown, setSelectedGodown] = React.useState('');

  const handleChange = (event) => {
    setsvalue(event.target.value);
  };
  const handleChange1 = (event) => {
    settvalue(event.target.value);
  };
  React.useEffect(() => {
    const fetchGodowns = async () => {
      try {
        const token = "PlyPark_APICalling_Token";
        const filter = "GetGodownDetails";
        const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
          token: token,
        });
        setGodowns(response.data.data);
      } catch (error) {
        // Handle error as needed
      }
    };
  
    fetchGodowns();
  }, []);
  
  React.useEffect(() => {
    const fetchRacks = async () => {
      try {
        if (selectedGodown) {
          const token = "PlyPark_APICalling_Token";
          const filter = "RackDetails";
          const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
            token: token,
            data: selectedGodown,
          });
          setrack(response.data.data);
        }
      } catch (error) {
        // Handle error as needed
      }
    };
  
    fetchRacks();
  }, [selectedGodown]);
  

    const propChange = async (event) => {
        event.preventDefault();
        const sendData = {
            svalue:svalue,
            tvalue:tvalue
        }
        
        if(sendData['svalue'] === sendData['tvalue'])
        {
            alert("Transfer Rack cannot be same");
            return;
        }
    const token = "PlyPark_APICalling_Token";
    const filter = "TransferRack";
    await axios
      .post(`AllUpdate_Details.php?filter=${filter}`, {
        token: token,
        data: sendData,
      })
      .then((response) => {
        if (response.data.success === true) {
          setOpen(true);
          reloadDataHandler();
          handleClose();
          setsvalue('');
          settvalue('');
          setSelectedGodown('');
        } else {
          alert("false");
        }
      })
      .catch((error) => {
        alert("false");
      });

      }

      const ShandleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Transfer Rack"}</DialogTitle>
        <ArgonTypography variant="caption" color="error" padding='10px'>Note: Transfering a Rack transfers the products from source rack to the target rack. Use this option after analysing the Racks as this can have an impact on stocks.</ArgonTypography>
        <form onSubmit={propChange}>
        <DialogContent sx={{width: 400,maxWidth: '100%'}}>
        <ArgonBox display="flex" alignItems="center" mb={0}>
  Select Godown :&nbsp;&nbsp;
  <Box sx={{ minWidth: 200, mb: 1 }}>
    <FormControl fullWidth>
      <Select
        value={selectedGodown}
        onChange={(event) => setSelectedGodown(event.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        required
        name="sourceGodown"
      >
        <MenuItem value="" disabled>
          ****Select the Godown****
        </MenuItem>
        {godowns.map((godownItem, index) => (
          <MenuItem key={index} value={godownItem.Id}>
            {godownItem.Name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
</ArgonBox>


        <ArgonBox display="flex" alignItems="center" mb={0}>
        Source Rack :&nbsp;&nbsp;
        <Box sx={{ minWidth: 200,mb:1}}>
        <FormControl fullWidth>
                
                <Select
                  value={svalue}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  name="selid"
                >
                  <MenuItem value="" disabled selected={!selectedGodown}>
                    ****Select the Source Rack**** 
                  </MenuItem>
                  {rack && rack.map((rackitem,index) => (
                    <MenuItem key={index} value={rackitem.Id}>
                      {rackitem.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </Box>

        </ArgonBox> 
        <ArgonBox display="flex" alignItems="center" mb={0}>
        Target Rack :&nbsp;&nbsp;
        <Box sx={{ minWidth: 200}}>
         <FormControl fullWidth>
                
                <Select
                  value={tvalue}
                  onChange={handleChange1}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  name="selid"
                >
                  <MenuItem value="" disabled selected={!selectedGodown}>
                    ****Select the Target Rack**** 
                  </MenuItem>
                  {rack && rack.map((rackitem,index) => (
                    <MenuItem key={index} value={rackitem.Id}>
                      {rackitem.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </Box>
        </ArgonBox>  
        </DialogContent>
        <DialogActions>
          <ArgonButton onClick={handleClose} variant="outlined" color="error">Cancel</ArgonButton>
          <ArgonButton variant="contained" color='success' type='submit'>Transfer</ArgonButton>
        </DialogActions>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={Sopen}
          autoHideDuration={5000}
          onClose={ShandleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={ShandleClose} severity="success" sx={{ width: "100%" }}>
            Success: Rack Transferred Successfully
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

RTDialogBox.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    reloadDataHandler: PropTypes.func,
  };