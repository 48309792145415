/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import PropTypes, { any, string } from "prop-types";
import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 MUI base styles
import borders from "assets/theme/base/borders";
// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import ArgonInput from "components/ArgonInput";
import "select2/dist/css/select2.min.css"; // Import Select2 CSS
import $ from "jquery"; // Import jQuery (required by Select2)
import "select2"; // Import Select2 JS
import "./Style.css";
import axios from "axios";
import { Alert, Snackbar, Stack, Typography } from "@mui/material";
// type products={
//     prodid: Number;
//     prodname: String;
//     quantity:Number
// };

function FormDetails({ updateCart, getDetails }) {
  const selectRef = React.useRef();
  const selectRef1 = React.useRef();

  const { borderWidth, borderColor } = borders;
  const [Godown, setGodown] = React.useState([]);
  const [GodownVal, setGodownVal] = React.useState(1);
  const [Payment, setPayment] = React.useState(10);
  const [Type, setType] = React.useState(10);
  const [ProductData, setProductData] = React.useState([]);
  const [Product, setProduct] = React.useState("");
  const [ProductName, setProductName] = React.useState("");

  const [Qty, setQtyVisibility] = React.useState(false);
  const [QtyVal, setQtyVal] = React.useState(0);

  const [RackData, setRackData] = React.useState([]);
  const [RackId, setRackId] = React.useState("");
  const [RackName, setRackName] = React.useState("");

  const [disable, setDisable] = React.useState(false);
  const [ProdCart, setProdCart] = React.useState([]);
  const [bd, setbd] = React.useState([]);
  const [Sopen, setOpen] = React.useState(false);

  const handleChange3 = (event) => {
    alert("Called");
    // Set the product id
  const selectedProductId = event.target.value;
  setProduct(selectedProductId);

  // Set the product Name
  const selectedProductName =
    event.target.options[event.target.selectedIndex].text;
  setProductName(selectedProductName);
  };

  const handleChange = (event) => {
    setGodownVal(event.target.value); // Ensure Godown is an array
  };

  const handleChange1 = (event) => {
    setPayment(event.target.value);
  };
  const handleChange2 = (event) => {
    setType(event.target.value);
  };
  const ShandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSave = async () => {
    setDisable(true);
    const bdd = {
      godown: GodownVal,
      payment: Payment,
      type: Type,
    };
    setbd([...bd, bdd]);
    // setbd((prevBd) => [...prevBd, bdd]);

    if (Type === 10) {
      //Setting the data in product dropdown
      try {
        const token = "PlyPark_APICalling_Token";
        const filter = "ProductDetails";
        const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
          token: token,
        });

        const Proddata = response.data.data;
        setProductData(Proddata);
      } catch (error) {
        alert("Internal Error! Kindly contact admin");
      }

      //Setting the data in Rack dropdown
      try {
        const token = "PlyPark_APICalling_Token";
        const filter = "RackDetails";
        const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
          token: token,
          data: GodownVal,
        });

        const RackData = response.data.data;
        setRackData(RackData);
      } catch (error) {
        alert(error.response.data.message);
      }
    } else if (Type === 20) {

      setQtyVisibility(true);
      //Setting the data in product dropdown
      try {
        const token = "PlyPark_APICalling_Token";
        const filter = "ProductDetailsFromStocks";
        const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
          token: token,
          data: GodownVal,
        });

        const Proddata = response.data.data;
        setProductData(Proddata);
      } catch (error) {
        alert(error.response.data.message);
      }
    }
  };

  const handleAdd = (event) => {
    event.preventDefault();
    const prdadd = {
      prodid: Product,
      rackid: RackId,
      rackname: RackName,
      prodname: ProductName,
      quantity: event.target.Qty.value,
    };
    if (Number(event.target.Qty.value) > Number(QtyVal) && Type === 20 || Number(event.target.Qty.value)=== 0)
    {
      setOpen(true);
    }
    else{
      setProdCart([...ProdCart, prdadd]);
      if(Type===20){setRackData([]);}
      setRackId("");
      setRackName("");
      setProduct("");
      setProductName("")
      event.target.Qty.value='';
      setQtyVal(0);
    }
    
  };

  React.useEffect(() => {
    getDetails(bd);
  }, [bd]);

  React.useEffect(()=> {
    const fetchData = async () => {
      if (Product && Type === 20)
      {
        setQtyVal(0);
        try {
          const token = "PlyPark_APICalling_Token";
          const filter = "RackDetailsFromStocks";
          
          const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
            token: token,
            data: GodownVal,
            prodid: Product
          });
          const RackData = response.data.data;
          setRackData(RackData);
        } catch (error) {
          alert(error.response.data.message);
        }
      }
    }
    fetchData();
    return () => {
      setRackId("");
      setRackName("");
      setQtyVal(0);
      if(Type===20){setRackData([])};
    };
  }, [Product]);

  React.useEffect(()=> {
    const fetchData = async () => {
      if (RackId && Type === 20)
      {
        try {
          const token = "PlyPark_APICalling_Token";
          const filter = "QtyDetailsFromStocks";
          
          const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
            token: token,
            data: GodownVal,
            prodid: Product,
            rackid: RackId
          });
          const QtyData = response.data.data[0]?.Qty || 0;
          setQtyVal(QtyData);
        } catch (error) {
          alert(error.response.data.message);
        }
      }
    }
    fetchData();
  }, [RackId]);

  React.useEffect(() => {
    updateCart(ProdCart);
    const $select = $(selectRef.current).select2({
      width: "300px", // Set the width of the select box
    });

    const $select1 = $(selectRef1.current).select2({
      width: "300px", // Set the width of the select box
    });

    $select.on("select2:select", async (e) => {
      setProduct(e.params.data.id);
      setProductName(e.params.data.text);
    });

    $select1.on("select2:select", (e) => {
      setRackId(e.params.data.id);
      setRackName(e.params.data.text);
    });
    return () => {
      $select.off("select2:select");
      $select.select2("destroy");

      $select1.off("select2:select");
      $select1.select2("destroy");
    };
  }, [ProdCart]);

  //For fetching the godown details to pop in the dropdown
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "PlyPark_APICalling_Token";
        const filter = "GetGodownDetails";
        const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
          token: token,
        });
        const godownDetails = response.data.data;
        setGodown(godownDetails);
      } catch (error) {
        alert("Internal Server Error!");
        // Handle error as needed
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Card id="delete-account">
        <ArgonBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <ArgonTypography variant="h6" fontWeight="medium">
            Enter Basic Details
          </ArgonTypography>
          {/* <ArgonButton variant="gradient" color="dark">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;Add New Card
        </ArgonButton> */}
        </ArgonBox>
        <ArgonBox p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <FormControl disabled={disable === true ? true : false}>
                <InputLabel id="demo-simple-select-label">
                  <ArgonTypography variant="h6">Select Godown*</ArgonTypography>
                </InputLabel>
                {Godown.length > 0 && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={GodownVal}
                    label="Select Godown*"
                    onChange={handleChange}
                    sx={{ minHeight: 50, minWidth: 200 }}
                    autoWidth
                  >
                    <MenuItem value="" disabled>
                      ****Select the Godown****
                    </MenuItem>

                    {Godown &&
                      Godown.map((godownItem) => (
                        <MenuItem key={godownItem.Id} value={godownItem.Id}>
                          {godownItem.Name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl disabled={disable === true ? true : false}>
                <InputLabel id="demo-simple-select-label">
                  <ArgonTypography variant="h6">Select Payment*</ArgonTypography>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Payment}
                  label="Select Type*"
                  onChange={handleChange1}
                  sx={{ minHeight: 50, minWidth: 200 }}
                  autoWidth
                >
                  <MenuItem value="" disabled>
                    ****Select Payment****
                  </MenuItem>
                  <MenuItem value={10}>Cash</MenuItem>
                  <MenuItem value={20}>Credit</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl disabled={disable === true ? true : false}>
                <InputLabel id="demo-simple-select-label">
                  <ArgonTypography variant="h6">Select Type*</ArgonTypography>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Type}
                  label="Select Type*"
                  onChange={handleChange2}
                  sx={{ minHeight: 50, minWidth: 200 }}
                  autoWidth
                >
                  <MenuItem value="" disabled>
                    ****Select Type****
                  </MenuItem>
                  <MenuItem value={10}>Stock In</MenuItem>
                  <MenuItem value={20}>Stock Out</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </ArgonBox>
        <Grid item xs={12} md={6}>
          <ArgonButton
            variant="gradient"
            color="dark"
            size="medium"
            onClick={handleSave}
            disabled={disable === true ? true : false}
          >
            <Icon sx={{ fontWeight: "bold" }}>save</Icon>
            &nbsp;Save & Proceed to Add Products
          </ArgonButton>
        </Grid>
      </Card>
      <br />
      <div style={{ display: disable ? "block" : "none" }}>
        <Card id="delete-account">
          <ArgonBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            <ArgonTypography variant="h6" fontWeight="medium">
              Enter Product Details
            </ArgonTypography>
            {/* <ArgonButton variant="gradient" color="dark">
    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
    &nbsp;Add New Card
  </ArgonButton> */}
          </ArgonBox>
          <form onSubmit={handleAdd}>
            <ArgonBox p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <select
                      ref={selectRef}
                      name="selectprd"
                      id="selUser"
                      label="Select Product*"
                      onChange={(e) => {
                        handleChange3(e);
                      }}
                      required
                      // sx={{minHeight:50,minWidth:300}}
                      // autoWidth
                    >
                      <option value="" disabled selected={!Product}>
                        Select Product
                      </option>
                      {ProductData &&
                        ProductData.map((prodItem, index) => (
                          <option value={prodItem.Id} key={index}>
                            {prodItem.Name}
                          </option>
                        ))}
                    </select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl>
                    <select
                      ref={selectRef1}
                      name="selectRack"
                      id="selRack"
                      label="Select Rack*"
                      onChange={(e) => setRackId(e.target.value)}
                      required
                      // sx={{minHeight:50,minWidth:300}}
                      // autoWidth
                    >
                      <option value="" disabled selected={!RackId}>
                        Select Rack
                      </option>
                      {RackData &&
                        RackData.map((RackItem, index) => (
                          <option value={RackItem.Id} key={index}>
                            {RackItem.Name}
                          </option>
                        ))}
                    </select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} style={{ display: Qty ? "block" : "none" }}>
                  <FormControl>
                    <ArgonTypography variant="h6" component="h2" mt={1} color="warning">
                      Availabe Qty in Rack {RackName}: {QtyVal}
                    </ArgonTypography>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <ArgonInput placeholder="Enter Quantity" size="large" name="Qty" type="number" required/>
                </Grid>
              </Grid>
            </ArgonBox>
            <Grid item xs={12} md={6}>
              <ArgonButton variant="gradient" color="dark" size="medium" type="submit">
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;Add Products
              </ArgonButton>
            </Grid>
          </form>
        </Card>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={Sopen}
          autoHideDuration={5000}
          onClose={ShandleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={ShandleClose} severity="error" sx={{ width: "100%" }}>
            Error: Entered quantity is more than the Available quantity
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

FormDetails.propTypes = {
  updateCart: PropTypes.func,
  getDetails: PropTypes.func,
};

export default FormDetails;
