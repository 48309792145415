import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Alert, Checkbox, Snackbar, Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";
import ArgonInput from "components/ArgonInput";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import axios from "axios";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfileDialogBox({ open, handleClose, user}) {
  const [Sopen, setOpen] = React.useState(false);
  const [Smess, setmess] = React.useState('');
  const [Ssev, setsev] = React.useState('');
 
  const ShandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const propChange = async (event) => {
    event.preventDefault();
    let pwd1=event.target.newpwd1.value;
    let pwd2=event.target.newpwd2.value;
    if(pwd1 !== pwd2)
    {
        setOpen(true);
        setmess('Error! Password do not match');
        setsev('error');
    }
    else
    {
        const sendData = {
            pwd: event.target.newpwd1.value,
            Mobile: localStorage.getItem('token'),
          };

          const token = "PlyPark_APICalling_Token";
          const filter = "UpdateUserPwd";
          await axios
            .post(`AllUpdate_Details.php?filter=${filter}`, {
              token: token,
              data: sendData,
            })
            .then((response) => {
              if (response.data.success === true) {
                setOpen(true);
                setmess('Success! Password Changed successfully');
                setsev('success');
                handleClose();

              } else {
                setOpen(true);
                setmess('Error! Password not changed');
                setsev('error');
              }
            })
            .catch((error) => {
                setOpen(true);
                setmess(error.response.data.message);
                setsev('error');
            });
    }
    
  };
  const inputStyle = {
    width: '100%',
    padding: '10px',
    margin: '3px 0',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '7px',
    outline: 'block',
    fontSize: '14px',
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Change Password"}</DialogTitle>
        <form onSubmit={propChange}>
          <DialogContent sx={{ width: 400, maxWidth: "100%" }}>
            <ArgonBox display="flex" alignItems="center" mb={2}>
              <ArgonInput placeholder="Name" value={user ? user: ""} />
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" mb={2}>
              <input
                type="password"
                placeholder="New Password"
                name="newpwd1"
                style={inputStyle}
                required={true}
              />
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" mb={2}>
              <input
                type="password"
                placeholder="Re-enter Password"
                name="newpwd2"
                style={inputStyle}
                required={true}
              />
            </ArgonBox>

          </DialogContent>
          <DialogActions>
            <ArgonButton onClick={handleClose} variant="outlined" color="error">
              Cancel
            </ArgonButton>
            <ArgonButton variant="contained" color="success" type="submit">
              Update
            </ArgonButton>
          </DialogActions>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={Sopen}
          autoHideDuration={5000}
          onClose={ShandleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={ShandleClose} severity={Ssev} sx={{ width: "100%" }}>
            {Smess}
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

ProfileDialogBox.defaultProps = {
  open: false,
  user: null,
};

ProfileDialogBox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  user: PropTypes.object,
};
