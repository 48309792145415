// @mui material components
import Card from "@mui/material/Card";
import * as React from 'react';
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import { ButtonGroup, Icon, Typography } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import GDDialogBox from './AddGodown';
import RackDialogBox from './AddRack';
import ProductDialogBox from "./AddProduct";
import axios from "axios";
import ArgonBadge from "components/ArgonBadge";
import EditProductDialogBox from "./EditProduct";
import RTDialogBox from "./RackTransfer";

const options = ['Godown & Racks ', 'Products' ];
const options1 = ['Godown','Rack','Product', 'Rack Transfer' ];
function GodownList() {
  const [tabValue, setTabValue] = React.useState("0");
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [GRRows, setGRRows] = React.useState();
    const [PRRows, setPRRows] = React.useState();
    const [GDopen, setGDOpen] = React.useState(false);
    const [Ropen, setROpen] = React.useState(false);
    const [Popen, setPOpen] = React.useState(false);
    const [EPopen, setEPOpen] = React.useState(false);
    const [RTopen, setRTOpen] = React.useState(false);
    const [reloadData, setReloadData] = React.useState(false);
    const [Heading, setHeading] = React.useState('Godown & Rack List');
    const [selectedUser, setSelectedUser] = React.useState(null);
    
    const handleChangeTab = (event, newValue) => {
      setTabValue(newValue);
    };

    const handleClickOpenGD = () => {
      setGDOpen(true);
    };
    const handleCloseGD = () => {
      setGDOpen(false);
    };

    const handleClickOpenR = () => {
      setROpen(true);
    };
    const handleCloseR = () => {
      setROpen(false);
    };

    const handleClickOpenP = () => {
      setPOpen(true);
    };
    const handleCloseP = () => {
      setPOpen(false);
    };

    const handleClickOpenRT = () => {
      setRTOpen(true);
    };
    const handleCloseRT = () => {
      setRTOpen(false);
    };

    const handleClickOpenEP = (prod) => {
      setEPOpen(true);
      setSelectedUser(prod); // Set the selected user when the Edit button is clicked
    };
    const handleCloseEP = () => {
      setEPOpen(false);
    setSelectedUser(null);
    };
    // const handleClick = () => {
    //   console.info(`You clicked ${options[selectedIndex]}`);
    // };
    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
      if(index===0)
      {
        handleClickOpenGD();
      }
      else if (index===1)
      {
        handleClickOpenR();
      }
      else if (index===2)
      {
        handleClickOpenP();
      }
      else if (index===3)
      {
        handleClickOpenRT();
      }
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };
    const reloadDataHandler = () => {
      setReloadData(!reloadData);
    };
    
    const GRcolumns = [
      { name: "Godowns", align: "left", sx: { backgroundColor: "#333", color: "#fff", fontWeight: "bold" }},
      { name: "Racks", align: "left",sx: { backgroundColor: "#333", color: "#fff", fontWeight: "bold" }},
    ];
    const PRcolumns = [
      { name: "Action", align: "left"},
      { name: "ProductName", align: "left"},
      { name: "Is_ReOrder", align: "left"},
      { name: "Reorder_Level", align: "left"},
    ];

    React.useEffect(() => {
      const fetchData = async () => {
        if(tabValue==="0")
        {
          setHeading("Godown & Rack List");
          try {
            const token = "PlyPark_APICalling_Token";
            const filter = "GodRackDetails";
            const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
              token: token,
            });
    
            const data = response.data.data;
            setGRRows(
              data.map((item) => ({
                Godowns: <ArgonTypography variant="button" fontWeight="bold">{item.godown_name}</ArgonTypography>,
                Racks: <ArgonTypography variant="button" fontWeight="medium" color="warning">{item.rack_names?item.rack_names:"No Racks Found"}</ArgonTypography> 
              }))
            );
          } catch (error) {
            alert("Internal Error! Kindly contact admin");
          }
        }
        else if(tabValue==="1")
        {
          setHeading("Products List");
          try {
            const token = "PlyPark_APICalling_Token";
            const filter = "ProductDetails";
            const response = await axios.post(`AllGet_Details.php?filter=${filter}`, {
              token: token,
            });
            
            const data = response.data.data;
            setPRRows(
              data.map((item,index) => ({
                ProductName: (<ArgonTypography variant="button" fontWeight="bold">{item.Name}</ArgonTypography>),
                Is_ReOrder: item.IsReorder==="1"?<ArgonBadge
                variant="gradient"
                badgeContent="Yes"
                color="success"
                size="xs"
                container
              />:<ArgonBadge
              variant="gradient"
              badgeContent="No"
              color="error"
              size="xs"
              container
            />,
                Reorder_Level: (<ArgonTypography variant="button" fontWeight="large">{item.IsReorder==="1"?item.Level:"NA"}</ArgonTypography>),
                Action: (
                  <ArgonBox>
                    <ArgonButton
                      variant="text"
                      color="warning"
                      onClick={() => handleClickOpenEP(item)}
                    >
                      <Icon>edit</Icon>&nbsp;Edit
                    </ArgonButton>
                  </ArgonBox>
                ),
              }))
            );
          } catch (error) {
            alert("Internal Error! Kindly contact admin");
          }
        }
      };
      fetchData();
    }, [tabValue,reloadData]);

    return (
      <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <ArgonTypography variant="h6">{Heading}</ArgonTypography>
              <ButtonGroup variant="gradient" ref={anchorRef} aria-label="split button">
        <ArgonButton
        variant="gradient" color="info"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp;
          Add New <ArrowDropDownIcon />
        </ArgonButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                <ArgonTypography
                    variant="h6"
                    fontWeight="medium">
              
                  {options1.map((option1, index) => (
                    <MenuItem
                      key={index}
                      // selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option1}
                    
                    </MenuItem>
                  ))}
                  </ArgonTypography>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
            </ArgonBox>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center"p={0} ml={2}>
            <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                {options.map((option, index) => (
                  <Tab key={index} label={option} value={index.toString()} />
                ))}
            </Tabs>
            </ArgonBox>
            
            <TabContext value={tabValue}>
              <TabPanel value="0">
                <ArgonBox style={{ overflowX: 'auto' }} sx={{ "& .MuiTableRow-root:not(:last-child)": { "& td": { borderBottom: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`, }, }, }}>
                  <Table columns={GRcolumns} rows={GRRows} />
                </ArgonBox>
              </TabPanel>
              <TabPanel value="1">
              <ArgonBox style={{ overflowX: 'auto' }} sx={{ "& .MuiTableRow-root:not(:last-child)": { "& td": { borderBottom: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`, }, }, }}>
                  <Table columns={PRcolumns} rows={PRRows} />
                </ArgonBox>
              </TabPanel>
              
            </TabContext>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
      <GDDialogBox GDopen={GDopen} handleCloseGD={handleCloseGD} reloadDataHandler={reloadDataHandler} />
      <RackDialogBox open={Ropen} handleClose={handleCloseR} reloadDataHandler={reloadDataHandler} />
      <ProductDialogBox open={Popen} handleClose={handleCloseP} reloadDataHandler={reloadDataHandler} />
      <EditProductDialogBox open={EPopen} handleClose={handleCloseEP} reloadDataHandler={reloadDataHandler} user={selectedUser}/>
      <RTDialogBox open={RTopen} handleClose={handleCloseRT} reloadDataHandler={reloadDataHandler}/>
    </DashboardLayout>
  );
}
  
export default GodownList;